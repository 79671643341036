import axios from 'axios'
import { FirebaseError } from 'firebase/app'
import Swal from 'sweetalert2'

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

export const fireSwalError = (error: unknown) => {
  if (axios.isCancel(error)) {
    return
  }

  if (axios.isAxiosError(error)) {
    const errorData = error.response?.data as { message: string } | undefined
    const errorMessage = errorData ? errorData.message : error.message
    Toast.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage,
    })
  } else if (error instanceof FirebaseError) {
    if (error.code === 'auth/invalid-login-credentials') {
      Toast.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have entered an invalid username or password',
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
      })
    }
  } else if (error instanceof Error) {
    Toast.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.message,
    })
  } else {
    console.error(error)
  }
}
