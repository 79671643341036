import { useEffect, useState, useContext, createContext, ReactNode } from 'react'
import {
  createUserWithEmailAndPassword,
  updatePassword as fbUpdatePassword,
  EmailAuthProvider,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  AuthProvider as FbAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { useQueryClient } from 'react-query'
import { constants } from 'config/constants'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userGuiding: any
  }
}

export type AuthInfo = {
  user: User
}

export interface AuthContextProps {
  auth: AuthInfo | null | undefined
  signInWithEmail: (email: string, password: string) => Promise<UserCredential>
  signInWithProvider: (provider: FbAuthProvider) => Promise<UserCredential>
  signUpWithEmail: (email: string, password: string) => Promise<UserCredential>
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>
  sendResetPasswordEmail: (email: string) => Promise<void>
  logOut: () => Promise<void>
}

const AuthContextValue = (): AuthContextProps => {
  const queryClient = useQueryClient()

  const [auth, setAuth] = useState<AuthInfo | null>()
  const [firebaseUser, setFirebaseUser] = useState<User | null>()

  const signInWithEmail = (email: string, password: string) => {
    return signInWithEmailAndPassword(getAuth(), email, password)
  }

  const signInWithProvider = (provider: FbAuthProvider) => {
    return signInWithPopup(getAuth(), provider)
  }

  const signUpWithEmail = (email: string, password: string) => {
    return createUserWithEmailAndPassword(getAuth(), email, password)
  }

  const sendResetPasswordEmail = (email: string) => {
    return sendPasswordResetEmail(getAuth(), email)
  }

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    if (!auth?.user) {
      return
    }
    await reauthenticateWithCredential(
      auth.user,
      EmailAuthProvider.credential(auth.user.email ?? '', oldPassword),
    )
    await fbUpdatePassword(auth.user, newPassword)
  }

  const logOut = async () => {
    await signOut(getAuth())
  }

  useEffect(() => onAuthStateChanged(getAuth(), setFirebaseUser), [])

  useEffect(() => {
    if (firebaseUser === undefined) {
      return
    }

    if (firebaseUser) {
      setAuth({ user: firebaseUser })
      if (window.userGuiding) {
        window.userGuiding.identify(firebaseUser.uid, {
          email: firebaseUser.email,
          name: firebaseUser.displayName,
          created_at: new Date(firebaseUser.metadata.creationTime as string).getTime(),
        })
      }
    } else {
      localStorage.removeItem(constants.LOCAL_STORAGE.ADMIN_AMEGO_WEBSITE_ID)
      localStorage.removeItem(constants.LOCAL_STORAGE.ADMIN_RETURN_URL)
      queryClient.clear()
      setAuth(null)
    }
  }, [queryClient, firebaseUser])

  return {
    auth,
    signInWithEmail,
    signInWithProvider,
    signUpWithEmail,
    updatePassword,
    sendResetPasswordEmail,
    logOut,
  }
}

const AuthContext = createContext<AuthContextProps>({
  auth: null,
  signInWithEmail: () => Promise.reject(),
  signInWithProvider: () => Promise.reject(),
  signUpWithEmail: () => Promise.reject(),
  updatePassword: () => Promise.reject(),
  sendResetPasswordEmail: () => Promise.reject(),
  logOut: () => Promise.reject(),
})

interface AuthProviderProps {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  return <AuthContext.Provider value={AuthContextValue()}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
