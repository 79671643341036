import axios from 'axios'

export interface User {
  uid: string
  is_owner: boolean
  access: string[]
  email: string
  display_name: string
  phone_number: string
}

export enum ModuleAccess {
  Analytics = 'b',
  Content = 'c',
  Community = 'y',
  Quest = 'q',
  Settings = 's',
  Account = 'a',
  Subscribers = 'u',
  Design = 'd',
  Messaging = 'n',
}

export interface UserResponse {
  website_id: number
  firebase_uid: string
  is_owner: boolean
  created_at: string
}

export const getUsers = async (): Promise<User[]> => axios.get('/users').then((res) => res.data)

export const changeUserAccess = async ({ email, access }: { email: string; access: string[] }) =>
  axios
    .post<{ email: string; access: string[] }, { data: UserResponse }>('/users', { email, access })
    .then((res) => res.data)
